<script
  lang="ts"
  context="module"
>
  const getObjectUrlForBlob = (asset: DownloadedImageAsset) => {
    const blob = new Blob([asset.data], { type: asset.mime });
    return URL.createObjectURL(blob);
  };

  type ResolvedSource =
    | null
    | {
        type: 'video';
        src: string;
      }
    | {
        type: 'image';
        src: string;
      }
    | {
        type: 'lottie';
        src: string;
      };

  const getComputerPropertiesFromSrc = (src: string | DownloadedImageAsset): ResolvedSource => {
    let src_from_blob = isDownloadedAsset(src) ? getObjectUrlForBlob(src) : src;
    const hasError = Boolean(!src);
    if (hasError) {
      return null;
    }

    const isLottie = src_from_blob?.slice(0, 1) === '{';
    if (isLottie) {
      return {
        type: 'lottie',
        src: src_from_blob,
      };
    }

    const isVideo = isDownloadedAsset(src) && src.mime?.slice(0, 5) === 'video';
    if (isVideo) {
      return {
        type: 'video',
        src: src_from_blob,
      };
    }

    return {
      type: 'image',
      src: src_from_blob,
    };
  };
</script>

<script lang="ts">
  import type { DownloadedImageAsset } from 'business-states/src/lib/models';
  import { isDownloadedAsset } from 'business-states/src/lib/models';
  import { createEventDispatcher, onMount } from 'svelte';
  import LottieMedia from './LottieMedia.svelte';
  import MediaPlaceholder from './MediaPlaceholder.svelte';

  export let src: string | DownloadedImageAsset;
  export let alt = '';
  export let style = '';
  export let id = '';
  export let placeholder = '';
  export let cover = false;
  export let cssClass = ''

  const dispatch = createEventDispatcher<{ load: HTMLVideoElement}>();

  // start: state
  let hasError = Boolean(!src);
  let resolvedSrc: ResolvedSource = getComputerPropertiesFromSrc(src);
  // end: state

  $: resolvedSrc = getComputerPropertiesFromSrc(src);

  let image: HTMLImageElement;
  let video: HTMLVideoElement;

  function imageError() {
    // console.log('GOT ERROR WITH VIDEO', e)
    resolvedSrc = null;
    //   switch (e.target.error.code) {
    //    case e.target.error.MEDIA_ERR_ABORTED:
    //      alert('You aborted the video playback.');
    //      break;
    //    case e.target.error.MEDIA_ERR_NETWORK:
    //      alert('A network error caused the video download to fail part-way.');
    //      break;
    //    case e.target.error.MEDIA_ERR_DECODE:
    //      alert('The video playback was aborted due to a corruption problem or because the video used features your browser did not support.');
    //      break;
    //    case e.target.error.MEDIA_ERR_SRC_NOT_SUPPORTED:
    //      alert('The video could not be loaded, either because the server or network failed or because the format is not supported.');
    //      break;
    //    default:
    //      alert('An unknown error occurred.');
    //      break;
    //  }
  }

  function on_load() {
    dispatch('load', video);
  }

  onMount(() => {
    if (!src) {
      on_load();
    } else if (resolvedSrc?.type === 'video') {
      video.src = resolvedSrc.src;
      video.load();
    }
  });
  let videoLoading = false;
  let lastLoadedSrc: string | null = null;
  $: {
    // If video then convert blob to base 64 code
    if (
      resolvedSrc?.type === 'video' &&
      typeof src !== 'string' &&
      !videoLoading &&
      resolvedSrc.src !== lastLoadedSrc
    ) {
      videoLoading = true;
      const blobToBase64 = (blob: Blob) => {
        return new Promise<string>((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64String = reader.result;
            resolve(base64String as string);
          };
          reader.onerror = () => {
            reject(new Error('Error reading Blob as Data URL'));
          };
          reader.readAsDataURL(blob); // Read the Blob as Data URL
        });
      };
      const blob = new Blob([src.data], { type: src.mime });
      blobToBase64(blob)
        .then((base64String:string) => {
          if (resolvedSrc) {
            resolvedSrc.src = base64String ?? URL.createObjectURL(blob);
            video.src = resolvedSrc.src;
            video.load();
            lastLoadedSrc = resolvedSrc.src; // Save the last loaded source to prevent reloading
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        })
        .finally(() => {
          videoLoading = false;
        });
    }
  }
</script>

{#if (!resolvedSrc || hasError) && placeholder}
  <MediaPlaceholder {placeholder} />
{:else if resolvedSrc?.type === 'lottie'}
  <LottieMedia src={resolvedSrc?.src} />
{:else if resolvedSrc?.type === 'video'}
  <video
    on:play={on_load}
    {id}
    {style}
    class="object-center h-full w-full m-auto {cssClass}"
    class:object-cover={cover}
    class:object-contain={!cover}
    loop
    muted
    autoplay
    on:error={imageError}
    bind:this={video}
  />
{:else if resolvedSrc?.type === 'image'}
  <img
    on:load={on_load}
    bind:this={image}
    {id}
    {style}
    {alt}
    class="m-auto object-center h-full w-full {cssClass}"
    class:object-cover={cover}
    class:object-contain={!cover}
    src={resolvedSrc?.src}
    data-src={resolvedSrc?.src}
    on:error={imageError}
  />
{:else}
  <svg 
    class="m-auto object-center h-full w-full {cssClass}"
    {style}
    class:object-cover={cover}
    class:object-contain={!cover}
    viewBox='0 0 24 24' width='1em' height='1em' xmlns='http://www.w3.org/2000/svg'>
    <path fill='currentColor' d='M21 5v6.59l-3-3.01l-4 4.01l-4-4l-4 4l-3-3.01V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2m-3 6.42l3 3.01V19a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-6.58l3 2.99l4-4l4 4' />
  </svg>
{/if}
