import type {
  DerivedModifierGroupState,
  ItemServiceStoreState,
  ModifierGroupIdStatePath,
} from '../states/menu/types';
import { getModifierGroupPizzaType } from '../models';
import type { KioskMenuItem } from '../models';
import {
  // eslint-disable-next-line camelcase
  ModifierGroupPizzaSpecificAttributes_ModifierGroupPizzaSpecificType,
} from '@grubbrr/nextgen-kiosk-client';
import { isPizzaItem } from '../states/menu/utils';
import { assertExists } from '../services/utils';

export const getCompoundIdForCategoryAndLayout = (layoutId: string, categoryId: string) => {
  return `${layoutId}-${categoryId}`;
};

// hack used to get around TS errors where discriminated union's are not properly narrowed
type InvalidOneOf = {
  oneofKind: undefined;
};

export const isValidOneOf = <T>(x: T | InvalidOneOf): x is T => {
  return x && !((x as InvalidOneOf).oneofKind === undefined);
};

const allowedModifierGroups = [
  // eslint-disable-next-line camelcase
  ModifierGroupPizzaSpecificAttributes_ModifierGroupPizzaSpecificType.Crust,
  // eslint-disable-next-line camelcase
  ModifierGroupPizzaSpecificAttributes_ModifierGroupPizzaSpecificType.Sauce,
  // eslint-disable-next-line camelcase
  ModifierGroupPizzaSpecificAttributes_ModifierGroupPizzaSpecificType.Topping,
];
const modifierGroupForSize =
  // eslint-disable-next-line camelcase
  ModifierGroupPizzaSpecificAttributes_ModifierGroupPizzaSpecificType.Size;
export type ChildModGroupService = { value: DerivedModifierGroupState };
export const fetchNestedModifiers = (
  modifierGroups: DerivedModifierGroupState[] | ChildModGroupService[],
  child = false,
  nestedModifierGroups: DerivedModifierGroupState[]
) => {
  const modGroup = child
    ? (modifierGroups[0] as ChildModGroupService).value
    : (modifierGroups[0] as DerivedModifierGroupState);
  // const group: ModGroupServiceContext = modGroup.state;
  // fetch its type
  const modifierGroupPizzaType = getModifierGroupPizzaType(modGroup.modifierGroup);
  // check if it is base, sauce and topping then only push
  if (modifierGroupPizzaType && allowedModifierGroups.includes(modifierGroupPizzaType))
    nestedModifierGroups.push(modGroup);
  if (
    modifierGroupPizzaType ===
    // eslint-disable-next-line camelcase
    ModifierGroupPizzaSpecificAttributes_ModifierGroupPizzaSpecificType.Sauce
  )
    return;
  const childModifiers = [...modGroup.modifiersState.values()];
  if (childModifiers.length > 0) {
    // If modifier is already selected then consider that index
    let index = 0;
    const indexHavingQty = childModifiers.findIndex((mod) => mod.state.display.quantity > 0);
    if (indexHavingQty !== -1) {
      index = indexHavingQty;
    }
    const childModifierGroups = childModifiers[index].modifierGroups;
    // If modifier group size then fetch all children like toppings, sauces else take first child
    if (modifierGroupPizzaType === modifierGroupForSize) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      // eslint-disable-next-line camelcase
      for (const [_, mod_group] of childModifierGroups.entries()) {
        // eslint-disable-next-line camelcase
        fetchNestedModifiers([mod_group], false, nestedModifierGroups);
      }
    } else {
      childModifierGroups.size > 0 &&
        fetchNestedModifiers(
          Array.from(childModifierGroups, ([name, value]) => ({ name, value })),
          true,
          nestedModifierGroups
        );
    }
  }
  return nestedModifierGroups;
};

export const checkIfCrustHasOnlyOneSelectedModifier = (
  modifierGroups: DerivedModifierGroupState[]
) => {
  if (modifierGroups.length === 0) return false;

  const modGrp = modifierGroups[0];
  const crustLength = Array.from(modGrp.state.display.modifiers).length;
  return checkIfCrustIsSelected(modifierGroups, true) && crustLength === 1;
};
const checkIfCrustIsSelected = (
  modifierGroups: DerivedModifierGroupState[],
  hasOnlyOneModifier = false
) => {
  const modGrp = modifierGroups[0];
  return (
    Array.from(modGrp.modifiersState.values()).filter((mod) => {
      const modState = mod.state;
      if (modState.display.quantity > 0 && mod.modifierGroups.size) {
        const selectedSizeModGroup = Array.from(mod.modifierGroups.entries(), ([name, value]) => ({
          name,
          value,
        }))[0].value;
        return (
          [...selectedSizeModGroup.modifiersState.values()].filter(
            (modifier) => modifier.state.display.quantity > 0
          ).length > 0 &&
          (!hasOnlyOneModifier ||
            (hasOnlyOneModifier && selectedSizeModGroup.state.display.modifiers.length === 1))
        );
      } else {
        return false;
      }
    }).length > 0
  );
};
export const checkModifiersValidation = (
  modifierGroups: DerivedModifierGroupState[],
  isAllowModifierSelection: boolean[]
) => {
  isAllowModifierSelection[0] = true;
  const nextAllow = checkIfCrustIsSelected(modifierGroups);
  for (const index in modifierGroups) {
    if (+index === 0) continue;
    isAllowModifierSelection[+index] = nextAllow;
  }
  return isAllowModifierSelection;
};

export const selectModGroupIndex = (
  menuItemService: ItemServiceStoreState,
  index: number
):
  | {
      activeTopLevelIndex: DerivedModifierGroupState;
      activeModGroupIndex: number;
      path: ModifierGroupIdStatePath;
    }
  | undefined => {
  const firstModifierGroupId = menuItemService.modifierGroups[0].modifierGroup.modifierGroupId;
  let activeTopLevelIndex = menuItemService.derivedModifierGroupState.get(firstModifierGroupId);
  assertExists(activeTopLevelIndex, 'Modifier group not found');

  let activeModGroupIndex = 0;
  const path: ModifierGroupIdStatePath = [activeTopLevelIndex?.modifierGroup.modifierGroupId];
  try {
    if (index !== 0) {
      // Take first modifier group which is base/crust
      const modGrp = menuItemService.derivedModifierGroupState.get(firstModifierGroupId);
      assertExists(modGrp, 'Modifier group not found');
      // Fetch selected base/crust modifier
      const selectedCrust = [...(modGrp?.modifiersState.values() ?? [])].find(
        (modifier) => modifier.state.display.quantity > 0
      );
      if (!selectedCrust) return;

      path.push(selectedCrust.modifier.modifierId);

      // Fetch size modifier group based on selected base/crust
      const selectedSizeModGroup = Array.from(
        selectedCrust.modifierGroups.entries(),
        ([name, value]) => ({ name, value })
      )[0].value;
      if (!selectedSizeModGroup) return;

      path.push(selectedSizeModGroup.modifierGroup.modifierGroupId);

      // Fetch selected size modifier
      const selectedSize = [...selectedSizeModGroup.modifiersState.values()].find(
        (modifier) => modifier.state.display.quantity > 0
      );
      if (!selectedSize) return;
      // Fetch modifier group with index based on selected size
      activeTopLevelIndex = Array.from(selectedSize.modifierGroups.entries(), ([name, value]) => ({
        name,
        value,
      }))[index - 1].value;
      path.push(selectedSize.modifier.modifierId);
      path.push(activeTopLevelIndex.modifierGroup.modifierGroupId);
    }
    activeModGroupIndex = index;
  } catch (error) {
    console.log('Error while selecting the modifier group', error);
  }
  return {
    activeTopLevelIndex,
    activeModGroupIndex,
    path,
  };
};

export const fetchNextModGroupIndex = (activeTopLevelIndex: DerivedModifierGroupState) => {
  if (
    activeTopLevelIndex &&
    activeTopLevelIndex.modifierGroup &&
    activeTopLevelIndex.modifierGroup.domainSpecificAttributes &&
    isValidOneOf(activeTopLevelIndex.modifierGroup.domainSpecificAttributes.attributes) &&
    activeTopLevelIndex.modifierGroup.domainSpecificAttributes.attributes.oneofKind ===
      'pizzaAttributes'
  ) {
    // If active modifier group is sauce then look for nested modifiers and if qty is > 0  then move to next mod group
    if (
      activeTopLevelIndex.modifierGroup.domainSpecificAttributes.attributes.pizzaAttributes.type ===
        // eslint-disable-next-line camelcase
        ModifierGroupPizzaSpecificAttributes_ModifierGroupPizzaSpecificType.Sauce &&
      activeTopLevelIndex.state.display.is_valid
    ) {
      // eslint-disable-next-line camelcase
      return ModifierGroupPizzaSpecificAttributes_ModifierGroupPizzaSpecificType.Sauce;
    } else if (
      activeTopLevelIndex.modifierGroup.domainSpecificAttributes.attributes.pizzaAttributes.type ===
      // eslint-disable-next-line camelcase
      ModifierGroupPizzaSpecificAttributes_ModifierGroupPizzaSpecificType.Crust
    ) {
      // eslint-disable-next-line camelcase
      return ModifierGroupPizzaSpecificAttributes_ModifierGroupPizzaSpecificType.Crust;
    }
  }
  return -1;
};
export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export const isModifierGroupHasSelectedModifier = (
  // eslint-disable-next-line camelcase
  modifier_group_service: DerivedModifierGroupState,
  index: number
) => {
  if (index === 1) {
    // eslint-disable-next-line camelcase
    const selectedCrust = [...modifier_group_service.modifiersState.values()].find(
      (modifier) => modifier.state.display.quantity > 0
    );
    if (!selectedCrust) return false;
    // Fetch size modifier group based on selected base/crust
    const selectedSizeModGroup = Array.from(
      selectedCrust.modifierGroups.entries(),
      ([name, value]) => ({ name, value })
    )[0].value;
    if (!selectedSizeModGroup) return false;
    // Fetch selected size modifier
    const selectedSize = [...selectedSizeModGroup.modifiersState.values()].find(
      (modifier) => modifier.state.display.quantity > 0
    );
    if (!selectedSize) return false;
    return true;
  } else if (index === 2 || index === 3) {
    const modifierGroups = Array.from(
      // eslint-disable-next-line camelcase
      [...modifier_group_service.modifiersState.values()][0].modifierGroups.entries(),
      ([name, value]) => ({ name, value })
    );
    const lengthCheck = index === 2 ? modifierGroups.length - 1 : 0;
    if (
      modifierGroups.filter((modGroup) =>
        [...modGroup.value.modifiersState.values()].find(
          (modifier) => modifier.state.display.quantity > 0
        )
      ).length > lengthCheck
    ) {
      return true;
    }
  }
  return false;
};
export const processItemForPizza = (item: KioskMenuItem) => {
  if (isPizzaItem(item)) {
    // Fetch the crusts from item
    const crusts = item.modifierGroups[0].modifiers.filter(
      (mod) => !mod.isInvisible && !mod.isActive
    );
    // If only one crust then change its default qty to 1
    if (crusts.length === 1) {
      crusts[0].defaultQuantity = 1;
    }
  }
  return item;
};
